import ba from "../src/media/ba.png";
import nomad from "../src/media/nomad.png";
import video from "../src/media/video.png";
import git from "../src/media/git.png";
import roulette from "../src/media/roulette.png";
import cocktail from "../src/media/cocktail.png";
import { Box, Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import { Button } from "@mui/material";

function Projects() {
  const projects = [
    {
      image: ba,
      title: "Big Apple Explorer",
      git: "https://github.com/Aru12345/capstone",
      video: "https://www.youtube.com/watch?v=Rg3GBEAtnic",
      description:
        "Users can SignUp using BCrypt, to review/rate restaurants in New York City. Built Rails backend API using PostgreSQL for data persistence and incorporated a New York City map using mapbox. Styled using MUI.",
    },
    {
      image: nomad,
      title: "Nomad Connect",
      git: "https://github.com/Aru12345/travelplanner",
      video: "https://www.youtube.com/watch?v=JUdgY7_-qyI&t=4s",
      description:
        "Digital nomads can view best places to live in. Integrated data from third party API & incorporated Google Maps.Utlized JSON server for CRUD functionalities. Styled using Tailwind CSS.",
    },
    {
      image: cocktail,
      description:
        "Search cocktail recipes ( DOM Manipulation Project). Added media queries for responsive design as well as CSS animations. Utlized local storage for offline data persistence",
      title: "Cocktail Lab",
      video: "https://www.youtube.com/watch?v=WDGpsNKszWU",
      git: "https://github.com/Aru12345/CocktailLab",
    },
    {
      image: roulette,
      title: "Interactive Roulette Game",

      video: "https://www.amaearthgroup.com/lobby",
      description:
        "Lead Frontend Engineer for internship project at AMA Earth Group.Enhanced front design for a game roulette. Designed UI elements with a focus on accessibility. Optimized mobile performance.",
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          height: "auto",
          width: "100%",
          backgroundImage: "linear-gradient(to bottom,#815e94, #0e0578)",
          pb: "0.5%",
        }}
        id="projectsPage"
      >
        <Box
          sx={{
            flex: 1,
            padding: 2,
            mt: { xs: "9%", sm: "4%" },
            boxSizing: "border-box",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mt: { xs: "3%", lg: "2%" },
              mb: { xs: "2%", lg: "1%" },
              fontSize: { xs: "1.5rem", md: "2rem" },
            }}
            fontFamily="cursive"
            fontWeight="bold"
            color="white"
            style={{ textShadow: "0.1px 0.4px 3px white" }}
          >
            Projects
          </Typography>
          <Box sx={{ padding: 0.1 }}>
            <Grid container spacing={1}>
              {projects.map((project, index) => (
                <Grid item xs={12} sm={12} md={5} key={index}>
                  <Card
                    sx={{
                      maxWidth: 405,
                      marginLeft: { lg: "28%", md: "25%", sm: "10%" },
                      marginBottom: "3%",
                      boxShadow: "2px 4px 5px #FFFFF0",
                      "&:hover": {
                        boxShadow: "2px 4px 10px pink",
                      },
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="110"
                        image={project.image}
                        alt={project.title}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{
                            fontSize: "bolder",
                            fontFamily: "cursive",
                            color: "#E33F77",
                          }}
                        >
                          {project.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontSize: "0.8rem",
                            color: "#0B5A98",
                            fontFamily: "Arial",
                            fontWeight: "bold",
                          }}
                        >
                          {project.description}
                        </Typography>
                        {project.title !== "Interactive Roulette Game" && (
                          <Button
                            sx={{
                              width: "10%",
                              height: "4%",
                              margin: "2%",
                              fontFamily: "Arial",
                              fontWeight: "bold",
                            }}
                            variant="contained"
                            href={project.git}
                            target="_blank"
                          >
                            Git
                          </Button>
                        )}

                        <Button
                          variant="contained"
                          href={project.video}
                          target="_blank"
                          sx={{ fontFamily: "Arial", fontWeight: "bold" }}
                        >
                          {project.title === "Interactive Roulette Game"
                            ? "Demo"
                            : "🎥"}
                        </Button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Projects;

/*
  return (
    <>
      <div className="bg-gradient-to-b from-cyan-800 to-blue-900 min-h-screen text-black">
        <div className="  h-24 m-0 p-0 ">
          <h1 className="text-white font-bold m-0 pt-20 text-2xl text-shadow-lg  align-center  shadow-blue-200 text-center ">
            PROJECTS
          </h1>
        </div>
        <div className="m-0 p-5 lg:p-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 lg:gap-12">
            <div className=" items-start text-wrap  bg-slate-300 m-10  p-3 rounded-md mt-2  shadow-2xl  shadow-blue-400 ">
              <h1 className="font-bold font-2xl m-1 text-shadow-lg shadow-blue-700 text-slate-800 ">
                Big Apple Explorer
              </h1>
              <img
                src={ba}
                alt="ba"
                className="w-full h-half rounded-md mt-2"
              />
              <p className="font-serif mt-2 mb-2">
                An app allowing users to SignUp for accounts using BCrypt, to
                review/rate restaurants in New York City.Built Rails backend API
                using PostgreSQL for data persistence and incorporated a New
                York City map using mapbox. Styled using MUI.
              </p>
              <h4 className="font-semibold text-sm">
                #Ruby on Rails #React #Bcrypt #MUI #PostgreSQL
              </h4>
              <div className="flex">
                <a
                  className=" text-white m-2 h-8 w-8 mb-4"
                  href="https://www.youtube.com/watch?v=Rg3GBEAtnic"
                  target="_blank"
                >
                  {" "}
                  <img className="h-full w-full mt-0.5" src={video} />
                </a>
                <a
                  className="bg-black text-white m-2 h-8 w-8"
                  href="https://github.com/Aru12345/capstone"
                  target="_blank"
                >
                  {" "}
                  <img className="h-full w-full mt-1" src={git} />
                </a>
              </div>
            </div>
            <div className=" items-start text-wrap  bg-slate-200 m-10  p-3 rounded-md mt-2  shadow-2xl  shadow-blue-400 ">
              <h1 className="font-bold font-2xl m-1 text-shadow-lg shadow-blue-700 text-slate-800 ">
                Nomad Connect
              </h1>
              <img
                src={nomad}
                alt="ba"
                className="w-full h-half rounded-md mt-2"
              />
              <p className="font-serif mt-2 mb-2">
                An app allowing digital nomads to view best places to live in.
                Integrated data from third party API & incorporated Google
                Maps.Utlized JSON server for CRUD functionalities. Styled using
                Tailwind CSS.
              </p>
              <h4 className="font-semibold text-sm">
                #Rapid API #Google Maps API #React #Tailwind
              </h4>
              <div className="flex">
                <a
                  className=" text-white m-2 h-8 w-8 mb-4"
                  href="https://www.youtube.com/watch?v=JUdgY7_-qyI&t=4s"
                  target="_blank"
                >
                  {" "}
                  <img className="h-full w-full mt-0.5" src={video} />
                </a>
                <a
                  className="bg-black text-white m-2 h-8 w-8"
                  href="https://github.com/Aru12345/travelplanner"
                  target="_blank"
                >
                  {" "}
                  <img className="h-full w-full mt-1" src={git} />
                </a>
              </div>
            </div>
            <div className=" items-start   bg-slate-200 m-10  p-3 rounded-md mt-2  shadow-2xl  shadow-blue-400 text-wrap ">
              <h1 className="font-bold font-2xl m-1 text-shadow-lg shadow-blue-700 text-slate-800 ">
                Cocktail Lab
              </h1>
              <img
                src={cocktail}
                alt="cocktail"
                className="w-full h-half rounded-md mt-2"
              />
              <p className="font-serif mt-2 mb-2 text-wrap">
                An app allowing users to view cocktail recipes ( DOM
                Manipulation Project).Added media queries for responsive design
                as well as Css animations.Utlized local storage for offline data
                persistence.
              </p>
              <h4 className="font-semibold text-sm">
                #Vanilla JS #Vanilla CSS #Media queries #JSON #CSS animations
              </h4>
              <div className="flex">
                <a
                  className=" text-white m-2 h-8 w-8 mb-4"
                  href="https://www.youtube.com/watch?v=WDGpsNKszWU"
                  target="_blank"
                >
                  {" "}
                  <img className="h-full w-full mt-0.5" src={video} />
                </a>
                <a
                  className="bg-black text-white m-2 h-8 w-8"
                  href="https://github.com/Aru12345/CocktailLab"
                  target="_blank"
                >
                  {" "}
                  <img className="h-full w-full mt-1" src={git} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Projects;

<div className="grid grid-cols-4 gap-12 ">
        <div className="bg-slate-200 w-10 h-1/2">
          <h1>Card 1</h1>
        </div>
        <div className="bg-slate-200">
          <h1>Card 2</h1>
        </div>
        <div className="bg-slate-200">
          <h1>Card 3</h1>
        </div>
      </div>*/
