import myself from "../src/media/myself.png";
import { color, motion } from "framer-motion";
import desk from "../src/media/desk.jpg";
import About from "./About";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import "./App.css";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 300, // default
      sm: 550, // default
      md: 900, // default
      lg: 1200, // default
      xl: 1536, // default
      customBp: 1800, // Adding a custom breakpoint
    },
  },
});

function Main() {
  return (
    <>
      <Box className="main_page" id="main">
        <Grid
          container
          display="flex"
          flexDirection="column"
          justifyContent="flex-start" // Aligns to the left
          alignItems={{ lg: "flex-start", xs: "flex-start" }}
          // height={{ lg: "43%", sm: "55%", xs: "auto" }}
          marginTop={{
            lg: "18%", // Width for large devices
            md: "25%", // Width for medium devices
            sm: "33%", // Width for small devices
            xs: "45%", // Width for extra small devices
          }}
          minHeight="100vh"
          marginLeft={"3%"}
          marginRight={"2%"}
          // backgroundColor={{ sm: "red" }}
        >
          <Grid
            item
            sx={{
              backgroundColor: "rgba(0, 0, 128, 0.5)",
              backdropFilter: "blur(30px)",
              /*
              width: {
                lg: "35%", // Width for large devices
                md: "33%", // Width for medium devices
                sm: "50%", // Width for small devices
                xs: "99%", // Width for extra small devices
              },*/
              width: { sm: "55%", lg: "45%" },
              height: { sm: "35%" },

              boxShadow: "0px 3px 5px aliceblue",

              borderRadius: "3%",
              transition: "backdrop-filter 0.3s ease", // Smooth transition for the hover effect
              "&:hover": {
                backdropFilter: "blur(80px)", // Reduce blur on hover
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: "0.6rem",
                  sm: "0.6rem",
                  md: "0.9rem",
                  lg: "0.9rem",
                },
                fontFamily: '"fantasy", "Arial", sans-serif',

                padding: { xs: "5%", sm: "7%", lg: "5%" },
                wordSpacing: "0.3rem",
              }} // Responsive font size
            >
              Hi, I'm <span style={{ fontWeight: "bold" }}>Aru!👋</span> <br />A{" "}
              <span style={{ fontWeight: "bold" }}>Fullstack Engineer</span>{" "}
              based in <span style={{ fontWeight: "bold" }}>New York City</span>{" "}
              🗽.
              <br />
              Resourceful and self-driven Web programmer with a passion for
              crafting exceptional user experiences through frontend
              engineering. Bringing a unique blend of technical expertise and
              problem-solving skills to every project, I'm always eager to
              continue growing my skills through various projects. When I'm not
              coding, you are likely going to see me at the gym 🏋️‍♀️. I also have
              a keen interest in world travel 🌎.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default Main;
