import { Box } from "@mui/material";
import { Typography, Divider } from "@mui/material";
import map from "../src/media/animemap.jpg";
import mypic from "../src/media/mypic.jpg";
import { Button } from "@mui/material";

function Contact() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          height: "auto",
          width: "100%",
          backgroundImage: "linear-gradient(to bottom, #0e0578,#815e94)",
          pb: "1%",
        }}
        id="contactPage"
      >
        <Box
          sx={{
            flex: 1,
            padding: 1,
            mt: "1%",
            mb: "4%",
            boxSizing: "border-box",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mt: { xs: "10%", lg: "5%" },
              fontSize: { xs: "1.5rem", md: "2rem" },
            }}
            fontFamily="cursive"
            fontWeight="bold"
            color="white"
            style={{ textShadow: "0.1px 0.4px 3px white" }}
          >
            Lets Connect!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", lg: "row" },
            }}
          >
            <Box
              component="img"
              alt="map"
              src={map}
              sx={{
                width: { xs: "60%", md: "25%" },
                margin: "1%",
                ml: "22%",
                mt: "3%",
                boxShadow: "2px 4px 5px aliceblue",
                "&:hover": {
                  boxShadow: "2px 4px 10px pink",
                },
              }}
            ></Box>
            <Box
              sx={{ borderLeft: { md: "1px solid grey" }, marginLeft: "2%" }}
            >
              <Box
                component="img"
                alt="map"
                src={mypic}
                sx={{
                  width: { xs: "60%", md: "47%" },
                  height: "65%",
                  margin: "1%",
                  ml: { xs: "22%", md: "17%", lg: "10%" },
                  mt: "6%",
                  boxShadow: "2px 4px 5px aliceblue",
                  "&:hover": {
                    boxShadow: "2px 4px 10px pink",
                  },
                }}
              ></Box>

              <Box
                sx={{
                  fontWeight: "bold",
                  marginLeft: { xs: "13vw", sm: "33%", md: "19%", lg: "17%" },
                  marginTop: { md: "5%" },
                  fontSize: "1rem",
                }}
              >
                <Button
                  sx={{
                    width: "5%",
                    height: "2%",
                    margin: "2%",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "0.6rem",
                  }}
                  variant="contained"
                  href="https://www.linkedin.com/in/arundhatiraicar/n"
                  target="_blank"
                >
                  LinkedIn
                </Button>
                <Button
                  sx={{
                    width: "5%",
                    height: "2%",
                    margin: "2%",
                    fontFamily: "Arail",
                    fontWeight: "bold",
                    fontSize: "0.6rem",
                  }}
                  variant="contained"
                  href="https://github.com/Aru12345"
                  target="_blank"
                >
                  GitHub
                </Button>
                <Button
                  sx={{
                    width: "5%",
                    height: "2%",
                    margin: "2%",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "0.6rem",
                  }}
                  variant="contained"
                  href="https://medium.com/@arundhatiraicar12"
                  target="_blank"
                >
                  Medium
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Contact;
